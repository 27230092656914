/*
 * Copyright © 2020-2022 Ocean Galaxy Inc. All Rights Reserved.
 * @Description:
 * @LastEditors: yongqiang
 * @LastEditTime: 2024-09-25 22:04:26
 */
export default function getUA() {
  let ua = navigator.userAgent.toLowerCase()
  let uaObj = {}

  let saEnv = 'others' //上报神策的属性

  uaObj.isFastSouGou = ua.match(/metasr/) && ua.match(/chrome/)
  uaObj.isMac =
    navigator.platform == 'Mac68K' ||
    navigator.platform == 'MacPPC' ||
    navigator.platform == 'Macintosh' ||
    navigator.platform == 'MacIntel'
  uaObj.isWin = navigator.platform == 'Win32' || navigator.platform == 'Windows'
  uaObj.isMobile = /iPhone|iPad|iPod|Android/i.test(ua)
  uaObj.isAndroid = /Android/i.test(ua)
  uaObj.isIOS = /iPhone|iPad|iPod|Macintosh|Mac OS/i.test(ua)
  uaObj.isWeiXin = /micromessenger/.test(ua)
  uaObj.isDingTalk = /dingtalk/.test(ua)
  uaObj.isQQ = /qq/.test(ua)
  uaObj.isTodayCamera = /todaycamera/i.test(ua)
  uaObj.isTodayCameraPro = /todaycamerapro/.test(ua)
  uaObj.isTodayCameraLite = /todaycameralite/.test(ua)
  uaObj.isHarmony = /ArkWeb/i.test(ua)
  /**
   * 鸿蒙的 UA 定义格式如下：
   * Mozilla/5.0 ({deviceType}; {OSName} {OSVersion}) AppleWebKit/537.36 (KHTML, like Gecko)
   * Chrome/114.0.0.0 Safari/537.36 ArkWeb/{ArkWeb VersionCode} {Mobile}
   *
   * deviceType 有以下取值：
   * Phone：表示手机设备。
   * Tablet：表示平板设备。
   * TV：表示电视设备。
   * Watch：表示手表设备。
   * Car：表示车载设备。
   * PC：表示个人电脑设备。
   * Other：表示其他类型的设备。
   *
   * @returns {string} deviceType
   */
  uaObj.getHarmonyDeviceType = () => {
    const deviceInfo = ua.match(/\(([^;]+);.*?\)/)
    return deviceInfo && deviceInfo[1] ? deviceInfo[1] : 'Unknow'
  }

  if (uaObj.isWeiXin) {
    saEnv = 'wechat'
  } else if (uaObj.isDingTalk) {
    saEnv = 'DingTalk'
  } else if (uaObj.isQQ) {
    saEnv = 'QQ'
  }

  uaObj.saEnv = saEnv

  if (uaObj.isWeiXin) {
    uaObj.weiXinVersion = ua
      .split(' ')
      .find((item) => item.startsWith('micromessenger'))
      .split('/')[1]
      .split('(')[0]
  }

  uaObj.getAppType = () => {
    /**
     * 与神策对齐常用的 App 运行时环境：IOS、Android、Harmony，
     */
    const commonAppType = {
      IOS: 1,
      Android: 2,
      Harmony: 8
    }
    if (uaObj.isHarmony) {
      return commonAppType.Harmony
    } else if (uaObj.isAndroid) {
      return commonAppType.Android
    } else {
      return commonAppType.IOS
    }
  }

  return uaObj
}
