/*
 * Copyright © 2020-2023 Ocean Galaxy Inc. All Rights Reserved.
 * @Description:
 * @LastEditors: yongqiang
 * @LastEditTime: 2023-12-06 19:52:37
 */

import axios from 'axios'
import { appVersionCode } from './todayUtils'

/* eslint-disable */
const isEnvProduction = process.env.NODE_ENV === 'production'

const serverUrl = isEnvProduction
  ? 'https://sareport.dayscamera.com/sa?project=WTForecast'
  : 'https://sareport.dayscamera.com/sa?project=production'

// const reportUrl =
//   process.env.NODE_ENV === 'staging'
//     ? 'https://staging.xhey.top'
//     : 'https://test.xhey.top'

let sensorInit = false
const sensor = async () => {
  const sa = await import(
    /* webpackChunkName: "sa-sdk-javascript" */ 'sa-sdk-javascript'
  )
  if (!sensorInit) {
    console.log('初始化sa-sdk-javascript')
    sensorInit = true
    sa.init({
      server_url: serverUrl,
      heatmap: {
        //是否开启点击图，默认 default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭
        clickmap: 'default',
        //是否开启触达注意力图，默认 default 表示开启，自动采集 $WebStay 事件，可以设置 'not_collect' 表示关闭
        scroll_notice_map: 'default',
        collect_tags: {
          div: true,
          img: true,
          p: true,
          span: true
        }
      },
      is_track_single_page: function () {
        return true
      },
      app_js_bridge: true,
      show_log: process.env.BUILD_ENV !== 'prod'
    })

    sa.quick('autoTrack')
  }
  return sa
}

const track = async (...args) => {
  const sa = await sensor()
  sa.track(...args)

  const [event, params] = args
  // if (!isEnvProduction) {
  //   axios.post(`${reportUrl}/next/point/create`, {
  //     event,
  //     params,
  //     versioncode: Number(appVersionCode())
  //   })
  // }
}

const quick = async (...args) => {
  const sa = await sensor()
  sa.quick(...args)
}

// const saRole = (role) => {
//   role = role * 1

//   switch (role) {
//     case 0:
//       return 'member'
//     case 1:
//       return 'chiefManager'
//     case 2:
//       return 'manager'
//     default:
//       return ''
//   }
// }

export default {
  track,
  quick
  // saRole
}
