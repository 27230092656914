import {
  fetchEnvAttrs,
  appVersion,
  appVersionCode,
  toInView
} from './todayUtils'
import querystring from 'querystring'
import axios from 'axios'
import { v4 as uuid } from 'uuid'
import todayAxios from './todayAxios'
import sa from './saUtilsACameraAsync'
import getUA from './getUA'

const isEnvProduction = process.env.NODE_ENV === 'production'

export const loadScript = (src, cb) => {
  let head = document.head || document.getElementsByTagName('head')[0]
  let script = document.createElement('script')

  cb = cb || function () {}

  script.type = 'text/javascript'
  script.src = src

  if (!('onload' in script)) {
    script.onreadystatechange = function () {
      if (this.readyState !== 'complete' && this.readyState !== 'loaded') return
      this.onreadystatechange = null
      cb(script)
    }
  }

  script.onload = function () {
    this.onload = null
    cb(script)
  }

  head.appendChild(script)
}

export const formatNum = (num = 0) => {
  const IntString = String(Math.abs(num)).split('.')[0]
  const FloatString = String(Math.abs(num)).split('.')[1]
  const symbol = num < 0 ? '-' : ''
  const ReversedIntArray = IntString.split('').reverse()
  let intArray = []
  for (let i = 0; i < ReversedIntArray.length; i += 3) {
    intArray = intArray.concat(ReversedIntArray.slice(i, i + 3))
    if (i + 3 < ReversedIntArray.length) {
      intArray.push(',')
    }
  }
  if (FloatString && FloatString.length) {
    return symbol + intArray.reverse().join('') + '.' + FloatString
  }
  return symbol + intArray.reverse().join('')
}

export const copyText = (text) => {
  // 数字没有 .length 不能执行selectText 需要转化成字符串
  const textString = text.toString()
  let input = document.querySelector('#copy-input')
  if (!input) {
    input = document.createElement('input')
    input.id = 'copy-input'
    input.readOnly = 'readOnly' // 防止ios聚焦触发键盘事件
    input.style.position = 'absolute'
    input.style.left = '-1000px'
    input.style.zIndex = '-1000'
    document.body.appendChild(input)
  }

  input.value = textString
  // ios必须先选中文字且不支持 input.select()
  selectText(input, 0, textString.length)

  // input自带的select()方法在苹果端无法进行选择，所以需要自己去写一个类似的方法
  // 选择文本。createTextRange(setSelectionRange)是input方法
  function selectText(textBox, startIndex, stopIndex) {
    if (textBox.createTextRange) {
      //ie
      const range = textBox.createTextRange()
      range.collapse(true)
      range.moveStart('character', startIndex) //起始光标
      range.moveEnd('character', stopIndex - startIndex) //结束光标
      range.select() //不兼容苹果
    } else {
      //firefox/chrome
      textBox.setSelectionRange(startIndex, stopIndex)
      textBox.focus()
    }
  }

  if (document.execCommand('copy')) {
    document.execCommand('copy')
  }
  input.blur()
  return document.execCommand('copy')
}

// 校验是否是一个数字
export const isNumber = (num) => {
  return typeof num === 'number' && !isNaN(num)
}

/**
 * 计算出当前屏幕下的响应值
 * 1. 根据当前屏幕宽度计算出当前 1 rem 的值
 * 2. 由于我们是基于在375分辨率下开发，在375屏幕下 1rem = 37.5 所以有一个公式： num/37.5 = 需要计算出的响应大小/当前的rem
 * 3. 需要计算出的响应大小 = 当前的rem * (num/37.5)
 * @param num 375屏幕下设置的数值  （数字、数字字符串）
 * @param maxWidth 最大宽度限制，默认是540，传入false表示最大宽度为屏幕宽度
 * @returns {number} 返回响应值
 */
export function setResponseSize(num, maxWidth = false) {
  if (isNumber(num) || isNumber(Number(num))) {
    let clientWidth = document.documentElement.clientWidth
    let max = maxWidth === false ? clientWidth : maxWidth || 540
    clientWidth = clientWidth >= max ? max : clientWidth
    const rem = clientWidth / 10
    const ratio = num / 37.5
    return Math.floor(rem * ratio)
  }
  return num
}

/**
 * 防抖函数，一段时间内只执行第一次或者最后一次
 * @param fn 原函数
 * @param timeout 超时时间
 * @param immediate 是否立即执行 false 表示最后一次执行
 * @returns {(function(...[*]): void)|*}
 */
export const debounce = (fn, timeout = 200, immediate = false) => {
  let timer = null
  return function (...args) {
    if (immediate && !timer) {
      fn && fn.call(this, ...args)
    }
    if (timer) {
      window.clearTimeout(timer)
      timer = null
    }
    timer = setTimeout(() => {
      !immediate && fn && fn.call(this, ...args)
      window.clearTimeout(timer)
      timer = null
    }, timeout)
  }
}

/**
 * 获取udesk在线客服链接
 * @param {*} isFetchEnvAttr 是否传递用户信息
 * @param {*} fromPage 从哪个页面打开在线客服
 * @returns
 */
export const getContactUrl = async ({
  isFetchEnvAttr = true,
  fromPage,
  pluginId
} = {}) => {
  let url = `https://huihaiyinhe.s4.udesk.cn/im_client/?web_plugin_id=${
    pluginId || '30149'
  }`

  let query = {
    c_cf_来源页面: fromPage // 用户从哪个页面过来
  }

  if (isFetchEnvAttr) {
    const attrs = await fetchEnvAttrs()
    const web_token = attrs.user.userId || attrs.device.deviceId
    const { nonce, signature, timestamp } = await todayAxios
      .post(
        '/next/app/h5/customer/service/token',
        {
          webToken: web_token
        },
        {
          isBase64DecodeHttpResult: false
        }
      )
      .then((res) => res.data.data)

    const vipType = {
      0: '普通包月',
      1: '普通包年',
      2: '订阅包月',
      3: '订阅包年'
    }

    const { user = {}, group = {}, device = {} } = attrs
    const { mobile = '', nickname = '', vipInfo: memberVipInfo = {} } = user
    const { groupId = '', vipInfo: groupVipInfo = {} } = group
    const { deviceId = '', model = '' } = device

    const memberVipType = memberVipInfo.isVip && memberVipInfo.vipType
    const groupVipType = groupVipInfo.isVIP && groupVipInfo.vipType

    query = {
      ...query,
      c_cf_登陆手机号: mobile, // 手机号
      c_name: nickname, // 姓名
      c_cf_团队号: groupId, // 团队号
      c_cf_个人会员类型:
        memberVipType === undefined ? '' : vipType[memberVipType], //个人会员类型
      c_cf_团队会员类型:
        groupVipType === undefined ? '' : vipType[groupVipType], //团队会员类型
      c_cf_状态码: deviceId, // 状态码
      c_cf_机型: model, //机型
      c_cf_版本号: appVersion(), // 版本号
      nonce,
      timestamp,
      web_token,
      signature
    }
  }

  url += `&${querystring.stringify(query)}`

  return url
}

/**
 * 获取天润在线客服链接
 * @param {*} isFetchEnvAttr 是否传递用户信息
 * @param {*} fromPage 从哪个页面打开在线客服
 * @returns
 */
export const getTRContactUrl = async ({
  isFetchEnvAttr = true,
  fromPage
} = {}) => {
  const url = 'https://webchat-sh.clink.cn/chat.html'

  const query = {
    accessId: 'b1eeb62e-c2f0-4d94-8e5d-ee0b28f936ae',
    language: 'zh_CN'
    // 'app-transparent-navbar': 1,
    // 'statusbar-text-color': 1,
    // 'app-hide-actions': 1
  }

  const customerFields = {
    版本号: appVersionCode(),
    来源页面: fromPage,
    autoUpdate: 1
  }

  if (isFetchEnvAttr) {
    try {
      const attrs = await fetchEnvAttrs()

      // 上报服务端
      todayAxios.post('/next/app/h5/tr/user/report', {
        userID: attrs.user.userId,
        deviceID: attrs.device.deviceId
      })

      const stat = await Promise.resolve().then(async () => {
        return todayAxios
          .post('/next/app/h5/tr/stat', {
            userID: attrs.user.userId,
            deviceID: attrs.device.deviceId
          })
          .then((res) => res.data.data)
      })

      const { user = {}, group = {}, device = {} } = attrs
      query.visitorId = user.userId || device.deviceId
      query.visitorName = user.nickname
      query.tel = user.mobile

      customerFields.userid = user.userId
      customerFields['姓名'] = user.nickname
      customerFields['登陆手机号'] = user.mobile
      customerFields['状态码'] = device.deviceId
      customerFields['机型'] = device.model
      customerFields['个人会员状态'] = user.vipInfo.isVip
      customerFields['个人会员历史充值次数'] = stat.userStats.orderRecordCount
      customerFields['个人会员历史充值金额'] =
        stat.userStats.orderRecordSumAmount
      customerFields['团队会员状态'] = group.isVip
      customerFields['团队会员历史充值次数'] = stat.groupStats.orderRecordCount
      customerFields['团队会员历史充值金额'] =
        stat.groupStats.orderRecordSumAmount
    } catch (e) {
      console.log(e)
    }
  }

  query.customerFields = JSON.stringify(customerFields)

  const qs = Object.entries(query)
    .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
    .join('&')

  return `${url}?${qs}`
}

/**
 * 跳转在线客服
 */
export const gotoContactUs = async ({ fromPage }) => {
  const url = await getTRContactUrl({
    fromPage
  })

  const envAttrs = await fetchEnvAttrs()

  toInView({
    url,
    groupId: envAttrs.group.groupId
  })
}

/**
 * 获取 oss 配置参数
 */
export const fetchOssData = async ({ bucketName, encrypted = true }) => {
  let ossData = window.localStorage.getItem('ossData')

  if (ossData) {
    ossData = JSON.parse(ossData)

    if (ossData[bucketName] && ossData[bucketName].expire > Date.now() / 1000) {
      return ossData[bucketName]
    }
  }

  let url = isEnvProduction
    ? 'https://h5api.xhey.top/h5/oss/tokens'
    : 'https://h5apitest.xhey.top/h5/oss/tokens'

  if (!encrypted) {
    return axios.post(url + '/unencrypted').then((res) => {
      if (res.status === 200) {
        window.localStorage.setItem('ossData', JSON.stringify(res.data.data))

        return res.data.data[bucketName]
      } else {
        throw new Error('获取token失败')
      }
    })
  }

  return todayAxios.post(url).then((res) => {
    if (res.status === 200) {
      window.localStorage.setItem('ossData', JSON.stringify(res.data.data))

      return res.data.data[bucketName]
    } else {
      throw new Error('获取token失败')
    }
  })
}

/**
 * 上传文件
 */
export const uploadFile = async ({ ossData, path, file }) => {
  const filename = uuid() + '.png'

  const params = {
    key: `${path}/${filename}`,
    OSSAccessKeyId: ossData.accessid,
    policy: ossData.policy,
    Signature: ossData.signature,
    file
  }

  let formData = new FormData()

  for (let key in params) {
    formData.append(key, params[key])
  }

  return axios
    .post('https://net-cloud.oss-cn-beijing.aliyuncs.com/', formData)
    .then((res) => {
      return `https://net-cloud.xhey.top/${path}/${filename}`
    })
}

export const base64ImgtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const suffix = mime.split('/')[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime
  })
}

/**
 * 根据dataURL创建Image
 * @param dataURL
 * @returns {Promise<*>}
 */
export const createImageWithDataURL = (dataURL) => {
  const image = new Image()
  image.setAttribute('crossOrigin', 'Anonymous')
  image.src = dataURL
  return new Promise((resolve) => {
    image.onload = () => resolve(image)
  })
}

// 浏览器已经自动解码了，这里二次解码可能会出错，同try catch 防止浏览器解码错误
export const decodeURIComponentSafe = (uri, mod = 0) => {
  console.log('decodePrevUrl:', uri)
  let out = String(),
    arr,
    i = 0,
    l,
    x
  arr = uri.split(/(%(?:d0|d1)%.{2})/)
  for (l = arr.length; i < l; i++) {
    try {
      x = decodeURIComponent(arr[i])
    } catch (e) {
      x = mod ? arr[i].replace(/%(?!\d+)/g, '%25') : arr[i]
    }
    out += x
  }
  console.log('decodeResultUrl:', out)
  return out
}

// 首先对不安全字符进行转译，转译完毕再进行编码
export const encodeURIComponentSafely = (uri) => {
  console.log('encodePrevUrl:', uri)
  const encodeSearchKey = (key) => {
    const encodeArr = [
      {
        code: '%',
        encode: '%25'
      },
      {
        code: '?',
        encode: '%3F'
      },
      {
        code: '#',
        encode: '%23'
      },
      {
        code: '&',
        encode: '%26'
      },
      {
        code: '=',
        encode: '%3D'
      }
    ]
    return key.replace(/[%?#&=]/g, ($, index, str) => {
      for (const k of encodeArr) {
        if (k.code === $) {
          return k.encode
        }
      }
    })
  }
  let result = encodeURIComponent(encodeSearchKey(uri))
  console.log('encodeResultUrl:', result)
  return result
}

/**
 * 水平滚动到指定的位置
 * @param scroller 滚动容器
 * @param to 滚动的距离
 * @param duration 滚动延迟
 */
export const scrollLeftTo = (scroller, to, duration) => {
  let count = 0
  const from = scroller.scrollLeft
  const frames = duration === 0 ? 1 : Math.round((duration * 1000) / 16)

  function animate() {
    scroller.scrollLeft += (to - from) / frames

    if (++count < frames) {
      requestAnimationFrame(animate)
    }
  }

  animate()
}

// 上报性能监控数据 fromPlace: 需要记录的页面
export const sensorTrackPerformanceData = (fromPlace) => {
  const UA = getUA()
  let timing = window.performance && window.performance.timing
  // Time to Interactive 在主文档的解析器结束工作
  let TTI = timing.domInteractive - timing.fetchStart
  // DOMContentLoaded  初始的 HTML 文档被完全加载和解析完成之后
  let DCL = timing.domContentLoadedEventEnd - timing.fetchStart
  // onload 加载完成耗时
  let L = timing.loadEventStart - timing.fetchStart
  // time to frist byte 获取首字节的耗时
  let TTFB = timing.responseStart - timing.fetchStart

  if (window.performanceSensorData) {
    window.performanceSensorData.H5L = L
    window.performanceSensorData.H5DCL = DCL
    window.performanceSensorData.H5TTI = TTI
    window.performanceSensorData.H5TTFB = TTFB

    let data = JSON.stringify(window.performanceSensorData)
    console.table({
      fromPlace,
      performanceSensorData: window.performanceSensorData
    })
    sa.track('h5_performance_data', {
      fromPlace,
      data: data,
      ...window.performanceSensorData,
      link: window.location.href,
      release: '1.0.0',
      env: isEnvProduction ? 'production' : 'test',
      os: UA.isIOS ? 'IOS' : UA.isAndroid ? 'android' : 'other'
    })
    return window.performanceSensorData
  }
}

/**
 * 对字体按需加载
 * @param fontName
 * HYXinRenWenSong-55W
 * HYXinRenWenSong-75W
 * Kingnam Bobo
 * qiantuhouheiti
 * Slideqiuhong
 * WDCH
 * ZiZhiQuXiMaiTi
 * AlibabaPuHuiTi
 * @param text 输入的文本
 * @returns {Promise<*>}
 */
export const loadFonts = async (fontName, text) => {
  const fontData = await axios.post(
    `https://webfont${
      process.env.NODE_ENV === 'production' ? '' : '-test'
    }.xhey.top/api/webfont`,
    {
      font: fontName,
      text: [...new Set(text.replace(/\n/g, '').split(''))].join('')
    }
  )
  const fontFamily = new FontFace(
    fontName,
    `url(data:application/x-font-ttf;charset=utf-8;base64,${fontData.data})`
  )
  document.fonts.add(fontFamily)
  return await fontFamily.load()
}

export const getH5LinkEnv = () => {
  const isLocal = window.location.host.includes('localhost')
  if (isLocal) {
    return 'local'
  }
  const match = /h5(.*)?.xhey.top$/i.exec(window.location.host)
  const env = match ? match[1] || '' : ''
  if (env.includes('dev')) return 'dev'
  if (env.includes('test')) return 'test'
  return 'prod'
}

/**
 * hex颜色转rgba
 * @param {*} hex
 * @param {*} opacity
 * @returns
 */
export const hexToRgba = (hex, opacity) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  if (opacity !== undefined && opacity >= 0 && opacity <= 1) {
    return `rgba(${r}, ${g}, ${b}, ${opacity})`
  } else {
    return `rgb(${r}, ${g}, ${b})`
  }
}

// 埋点上报 role
export const saRole = (role) => {
  switch (role) {
    case 0:
      return 'member'
    case 1:
      return 'chiefManager'
    case 2:
      return 'manager'
    default:
      return ''
  }
}

export const getRandomInt = (min, max) => {
  min = Math.ceil(min) // 向上取整
  max = Math.floor(max) // 向下取整
  return Math.floor(Math.random() * (max - min + 1)) + min
}
