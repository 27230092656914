/*
 * Copyright © 2020-2022 Ocean Galaxy Inc. All Rights Reserved.
 * @Description:
 * @LastEditors: yongqiang
 * @LastEditTime: 2024-09-14 15:49:32
 */
/* eslint-disable */
import axios from 'axios'
import { Toast } from 'vant'
import { encodeHttpParams, decodeHttpResult } from './todayUtils'
import getUA from './getUA'

const instance = axios.create({
  timeout: 30000
})

const isEnvTest = process.env.NODE_ENV === 'test'
const isEnvProduction = process.env.NODE_ENV === 'production'

if (isEnvProduction) {
  instance.defaults.baseURL = 'https://today.xhey.top'
} else if (isEnvTest) {
  instance.defaults.baseURL = 'https://test.xhey.top'
} else {
  // instance.defaults.baseURL = 'https://dev.xhey.top'
  instance.defaults.baseURL = 'https://test.xhey.top'
}

export const requestInterceptor = instance.interceptors.request.use(
  async (config) => {
    if (!navigator.onLine) {
      Toast('网络异常')

      return Promise.reject(new Error('网络异常'))
    }
    // console.log(config)
    // console.log(99999)

    let params = config.data

    if (isEnvProduction && config.data) {
      params = await encodeHttpParams(config.data)
    }

    const headers = getUA().isTodayCameraPro
      ? {
          ...config.headers,
          app_name: 'pro'
        }
      : config.headers

    if (
      config.url.startsWith('https://h5api.xhey.top') ||
      config.url.startsWith('https://h5apitest.xhey.top')
    ) {
      delete headers.app_name
    }

    return {
      ...config,
      data: params,
      headers
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

export const responseInterceptor = instance.interceptors.response.use(
  async (response) => {
    let result = response.data
    let isBase64Decode = false
    // 单个接口配置是否需要base64解密，优先级最大
    if (response.config.isBase64DecodeHttpResult !== undefined) {
      isBase64Decode = response.config.isBase64DecodeHttpResult
    } else {
      // 单个接口没有配置时，根据整个实例是否配置为准
      isBase64Decode = !!instance.isBase64DecodeHttpResult
    }

    if (isEnvProduction) {
      result = await decodeHttpResult(response.data, isBase64Decode)
    }
    return {
      ...response,
      data: result
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default instance
